(function() {
    'use strict';

    angular
        .module('uzbekistanYieldCurvesApp')
        .controller('QuotingController', QuotingController);

    QuotingController.$inject = ['Quoting'];

    function QuotingController(Quoting) {

        var vm = this;

        vm.quotings = [];

        loadAll();

        function loadAll() {
            Quoting.query(function(result) {
                vm.quotings = result;
                vm.searchQuery = null;
            });
        }
    }
})();
