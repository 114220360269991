(function () {
    'use strict';
    angular
        .module('uzbekistanYieldCurvesApp')
        .factory('PaperErrorsCsv', PaperErrorsCsv);

    PaperErrorsCsv.$inject = ['$resource'];

    function PaperErrorsCsv($resource) {
        var resourceUrl = 'api/paper-errors/csv';

        return function (exportType) {
                return $resource(resourceUrl, {}, {
                    'get': {
                        method: 'GET',
                        responseType: "text",
                        transformResponse: function (data, headers) {
                            var response = {};
                            response.data = data;
                            response.headers = headers();
                            return response;
                        }
                    }
                });
        }
    }
})();
