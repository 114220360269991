(function() {
    'use strict';

    angular
        .module('uzbekistanYieldCurvesApp')
        .controller('PaperErrorDeleteController',PaperErrorDeleteController);

    PaperErrorDeleteController.$inject = ['$uibModalInstance', 'entity', 'PaperError'];

    function PaperErrorDeleteController($uibModalInstance, entity, PaperError) {
        var vm = this;

        vm.paperError = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            PaperError.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
