(function() {
    'use strict';

    angular
        .module('uzbekistanYieldCurvesApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('paper-price', {
            parent: 'entity',
            url: '/paper-price',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'uzbekistanYieldCurvesApp.paperPrice.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/paper-price/paper-prices.html',
                    controller: 'PaperPriceController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('paperPrice');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('paper-price-detail', {
            parent: 'paper-price',
            url: '/paper-price/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'uzbekistanYieldCurvesApp.paperPrice.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/paper-price/paper-price-detail.html',
                    controller: 'PaperPriceDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('paperPrice');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'PaperPrice', function($stateParams, PaperPrice) {
                    return PaperPrice.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'paper-price',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('paper-price-detail.edit', {
            parent: 'paper-price-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/paper-price/paper-price-dialog.html',
                    controller: 'PaperPriceDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['PaperPrice', function(PaperPrice) {
                            return PaperPrice.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('paper-price.new', {
            parent: 'paper-price',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/paper-price/paper-price-dialog.html',
                    controller: 'PaperPriceDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                dirtyPrice: null,
                                cleanPrice: null,
                                ytm: null,
                                residualMaturity: null,
                                code: null,
                                description: null,
                                dayCount: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('paper-price', null, { reload: 'paper-price' });
                }, function() {
                    $state.go('paper-price');
                });
            }]
        })
        .state('paper-price.edit', {
            parent: 'paper-price',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/paper-price/paper-price-dialog.html',
                    controller: 'PaperPriceDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['PaperPrice', function(PaperPrice) {
                            return PaperPrice.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('paper-price', null, { reload: 'paper-price' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('paper-price.delete', {
            parent: 'paper-price',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/paper-price/paper-price-delete-dialog.html',
                    controller: 'PaperPriceDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['PaperPrice', function(PaperPrice) {
                            return PaperPrice.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('paper-price', null, { reload: 'paper-price' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
