(function() {
    'use strict';

    angular
        .module('uzbekistanYieldCurvesApp')
        .controller('YcFaqController', YcFaqController);

    YcFaqController.$inject = ['$uibModalInstance'];

    function YcFaqController($uibModalInstance) {
        var vm = this;

        vm.clear = clear;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
