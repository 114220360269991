(function() {
    'use strict';

    angular
        .module('uzbekistanYieldCurvesApp')
        .controller('ParCurvePointDeleteController',ParCurvePointDeleteController);

    ParCurvePointDeleteController.$inject = ['$uibModalInstance', 'entity', 'ParCurvePoint'];

    function ParCurvePointDeleteController($uibModalInstance, entity, ParCurvePoint) {
        var vm = this;

        vm.parCurvePoint = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            ParCurvePoint.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
