(function () {
    'use strict';

    angular
        .module('uzbekistanYieldCurvesApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', '$rootScope', '$q', '$translate', 'Principal', 'LoginService', 'DateUtils',
        'DataUtils', 'availableDates', 'YieldCurveResultsOpen', 'ParCurvePointOpen', 'YieldCurvePointOpen',
        'DataExportOpen', 'ObservedDataCsv', 'PaperErrorsCsv', 'thresholdDate'];

    function HomeController($scope, $rootScope, $q, $translate, Principal, LoginService, DateUtils, DataUtils, availableDates,
        YieldCurveResultsOpen, ParCurvePointOpen, YieldCurvePointOpen, DataExportOpen, ObservedDataCsv,
        PaperErrorsCsv, thresholdDate) {
        var vm = this;

        //***************************************************************
        // basic auth operations
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        $scope.$on('authenticationSuccess', function () {
            getAccount();
        });
        getAccount();

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

        //***************************************************************
        //Y-axis title translation handling
        vm.langKey = $translate.storage().get('NG_TRANSLATE_LANG_KEY').trim().toLowerCase();
        vm.langKeyChart = (vm.langKey === 'uz') ? 'oz' : (vm.langKey==='uz-latn') ? 'uz' : vm.langKey;
        var translateChangeSuccess = $rootScope.$on('$translateChangeSuccess', function () {
            vm.langKey = $translate.storage().get('NG_TRANSLATE_LANG_KEY').trim().toLowerCase();
            vm.langKeyChart = (vm.langKey === 'uz') ? 'oz' : (vm.langKey==='uz-latn') ? 'uz' : vm.langKey;
            updateAxisTitle();
        });
        $rootScope.$on('$destroy', function () {
            if (angular.isDefined(translateChangeSuccess) && translateChangeSuccess !== null) {
                translateChangeSuccess();
            }
        });

        //***************************************************************

        //convert available dates to Moment objects
        vm.availableDates = availableDates.map(function (ad) {
            return moment(ad);
        });
        //define threshold date
        // vm.thresholdDate = moment(thresholdDate[0]).format("ll");
        vm.thresholdDate = null;
        //get the latest available date if any
        vm.lastAvailable = null;
        if (vm.availableDates && vm.availableDates.length > 0) {
            vm.lastAvailable = vm.availableDates[vm.availableDates.length - 1];
        }
        //pre-select the latest available date on page load
        vm.selectedDates = [vm.lastAvailable];
        //switch to the month of the latest available date
        vm.activeMonth = vm.lastAvailable;
        //define default data set for the chart
        vm.chartData = {
            chartId: "yc_chart",
            lineSeries: {par: [], zero: []},
            lineLegends: []
        };
        vm.isSplineInterpolated = true;
        vm.curveType = "zero";
        vm.curveLimits = [];
        vm.chartMinTenor = null;
        vm.chartMaxTenor = null;
        vm.minSlider = 2;
        vm.maxSlider = 30;
        vm.sliderStep = 1;
        vm.curveRangeLimit = null;
        vm.curveRangeModel = null;
        vm.longDownload = false;
        //list all the methods
        vm.clearDates = clearDates;
        vm.getResultsForDate = getResultsForDate;
        vm.onCurveRangeChanged = onCurveRangeChanged;
        vm.onCurveRangeSaved = onCurveRangeSaved;
        vm.onDaySelected = onDaySelected;
        vm.onExcelExportClicked = onExcelExportClicked;
        vm.onParamsExportClicked = onParamsExportClicked;
        vm.onExportAllClicked = onExportAllClicked;
        vm.onErrorsExportClicked = onErrorsExportClicked;
        vm.onGetObsClicked = onGetObsClicked;
        vm.parCurveSelected = parCurveSelected;
        vm.updateAxisTitle = updateAxisTitle;
        vm.zeroCurveSelected = zeroCurveSelected;
        //add latest available curve to the chart (if any)
        if (vm.lastAvailable) {
            onDaySelected([], {date: vm.lastAvailable, mdp: {selected: false}});
            updateAxisTitle();
        }

        //***************************************************************
        function parCurveSelected() {
            vm.curveType = "par";
            updateCurveLimits();
            updateAxisTitle();
            // if (vm.chartMaxTenor === 731) {
            //     vm.chartMaxTenor = Math.min(1827, vm.curveLimits[1]);
            //     vm.curveRangeLimit = Math.min(5, Math.round(vm.curveLimits[1] / 365));
            //     vm.curveRangeModel = Math.min(5, Math.round(vm.curveLimits[1] / 365));
            // }
            // vm.minSlider = Math.min(5, Math.round(vm.curveLimits[1] / 365));
        }

        function zeroCurveSelected() {
            vm.curveType = "zero";
            updateCurveLimits();
            updateAxisTitle();
            // vm.minSlider = Math.max(2, Math.round(vm.curveLimits[0] / 365));
        }

        function onCurveRangeChanged() {
            vm.curveRangeLimit = Math.max(2, vm.curveRangeModel);
            if (vm.langKey === 'ru') {
                vm.sliderTitle = (vm.curveRangeLimit < 5) ? 'года' : 'лет';
            }
        }

        function onCurveRangeSaved() {
            vm.chartMaxTenor = vm.curveRangeLimit * 365 + Math.ceil(vm.curveRangeLimit / 4);
        }

        function onParamsExportClicked() {
            var excelFormat = 'xlsx';
            DataExportOpen('excel-betas')
                .query({
                    format: excelFormat,
                    languageKey: $translate.storage().get('NG_TRANSLATE_LANG_KEY')
                })
                .$promise
                .then(function (response) {
                    var fileNameIndex = response.headers["content-disposition"].indexOf("filename=");
                    var fileName = "exported-parameters." + excelFormat;
                    if (fileNameIndex > -1) {
                        fileName = response.headers["content-disposition"].substr(fileNameIndex + "filename=".length);
                    }
                    DataUtils.saveFile(response.headers["content-type"],
                        response.data, fileName.substr(1, fileName.length - 2));
                });
        }

        function onExportAllClicked() {
            var excelFormat = 'xlsx';
            vm.longDownload = true;
            DataExportOpen('excel-all')
                .query({
                    format: excelFormat,
                    languageKey: $translate.storage().get('NG_TRANSLATE_LANG_KEY')
                })
                .$promise
                .then(function (response) {
                    var fileNameIndex = response.headers["content-disposition"].indexOf("filename=");
                    var fileName = "exported-all-results." + excelFormat;
                    if (fileNameIndex > -1) {
                        fileName = response.headers["content-disposition"].substr(fileNameIndex + "filename=".length);
                    }
                    DataUtils.saveFile(response.headers["content-type"],
                        response.data, fileName.substr(1, fileName.length - 2));
                })
                .finally(function () {
                    vm.longDownload = false;
                });
        }

        function onErrorsExportClicked() {
            PaperErrorsCsv()
                .get({lang: vm.langKey})
                .$promise
                .then(function (response) {
                    DataUtils.saveFile(response.headers["content-type"],
                        response.data, "paper_errors_" + moment().format('YYYYMMDD') + ".csv");
                });
        }

        function onExcelExportClicked() {
            var excelFormat = 'xlsx';
            var dateList = vm.selectedDates.map(function (date) {
                return DateUtils.convertLocalDateToServer(date.toDate());
            });
            if (!dateList || dateList.length === 0) {
                return;
            }
            DataExportOpen('excel')
                .query({
                    date: dateList,
                    format: excelFormat,
                    languageKey: $translate.storage().get('NG_TRANSLATE_LANG_KEY')
                })
                .$promise
                .then(function (response) {
                    var fileNameIndex = response.headers["content-disposition"].indexOf("filename=");
                    var fileName = "exported-data." + excelFormat;
                    if (fileNameIndex > -1) {
                        fileName = response.headers["content-disposition"].substr(fileNameIndex + "filename=".length);
                    }
                    DataUtils.saveFile(response.headers["content-type"],
                        response.data, fileName.substr(1, fileName.length - 2));
                });
        }

        function onGetObsClicked() {
            ObservedDataCsv()
                .get({lang: vm.langKey})
                .$promise
                .then(function (response) {
                    DataUtils.saveFile(response.headers["content-type"],
                        response.data, "observed_data_" + moment().format('YYYYMMDD') + ".csv");
                });
        }

        function onDaySelected(event, dateObj) {
            var unselected = dateObj.mdp.selected;
            var dateString = DateUtils.convertLocalDateToServer(dateObj.date.toDate());
            if (unselected) {
                var curveIndex = vm.chartData.lineLegends
                    .findIndex(function (elem) {
                        return elem === dateString;
                    });
                if (curveIndex > -1) {
                    //remove line from the chart
                    vm.chartData.lineSeries.par.splice(curveIndex, 1);
                    vm.chartData.lineSeries.zero.splice(curveIndex, 1);
                    //remove line from the chart legend
                    vm.chartData.lineLegends.splice(curveIndex, 1);
                    updateCurveLimits();
                }
            } else {
                //load curves data
                getResultsForDate(dateString)
                    .then(function (res) {
                        var promises = [];
                        promises.push(ParCurvePointOpen.query({ycId: res.id}).$promise);
                        promises.push(YieldCurvePointOpen.query({ycId: res.id}).$promise);
                        $q.all(promises)
                            .then(function (resParAndZero) {
                                var resPar = resParAndZero[0];
                                var resZero = resParAndZero[1];
                                //add par line to the chart data
                                vm.chartData.lineSeries.par.push(resPar);
                                //add zero line to the chart data
                                vm.chartData.lineSeries.zero.push(resZero);
                                vm.chartData.lineLegends.push(dateString);
                                updateCurveLimits();
                            });
                    });
            }
        }

        function getResultsForDate(dateString) {
            return YieldCurveResultsOpen.get({date: dateString}).$promise;
        }

        function clearDates() {
            vm.chartData = {
                chartId: "yc_chart",
                lineSeries: {par: [], zero: []},
                lineLegends: []
            };
            vm.selectedDates = [];
        }

        function updateAxisTitle() {
            const transId = (vm.curveType === "par")
                ? 'home.yAxisTitlePar'
                : 'home.yAxisTitle';
            $translate(transId).then(function (translation) {
                vm.yAxisTitle = translation;
            }, function (translationId) {
                vm.yAxisTitle = translationId;
            });
        }

        function updateCurveLimits() {
            var minValue = Number.MAX_VALUE;
            var maxValue = Number.MIN_VALUE;
            const lineData = vm.chartData.lineSeries[vm.curveType];
            for (var ix = 0; ix < lineData.length; ix++) {
                if (angular.isDefined(lineData[ix])) {
                    for (var jx = 0; jx < lineData[ix].length; jx++) {
                        var xNum = parseFloat(lineData[ix][jx].deltaPeriods);
                        minValue = Math.min(minValue, xNum);
                        maxValue = Math.max(maxValue, xNum);
                    }
                }
            }
            vm.chartMinTenor = minValue;
            vm.chartMaxTenor = (vm.chartMaxTenor === null || vm.chartMaxTenor === Number.MIN_VALUE)
                ? maxValue
                : Math.min(maxValue, vm.chartMaxTenor);
            vm.minSlider = Math.max(2, Math.round(minValue / 365));
            vm.maxSlider = Math.round(maxValue / 365);
            vm.curveRangeLimit = Math.round(vm.chartMaxTenor / 365);
            vm.curveRangeModel = Math.round(vm.chartMaxTenor / 365);
        }
    }
})();
