(function() {
    'use strict';

    angular
        .module('uzbekistanYieldCurvesApp')
        .controller('YieldCurvePointController', YieldCurvePointController);

    YieldCurvePointController.$inject = ['YieldCurvePoint'];

    function YieldCurvePointController(YieldCurvePoint) {

        var vm = this;

        vm.yieldCurvePoints = [];

        loadAll();

        function loadAll() {
            YieldCurvePoint.query(function(result) {
                vm.yieldCurvePoints = result;
                vm.searchQuery = null;
            });
        }
    }
})();
