(function() {
    'use strict';

    angular
        .module('uzbekistanYieldCurvesApp')
        .controller('CurveLimitsDetailController', CurveLimitsDetailController);

    CurveLimitsDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'CurveLimits'];

    function CurveLimitsDetailController($scope, $rootScope, $stateParams, previousState, entity, CurveLimits) {
        var vm = this;

        vm.curveLimits = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('uzbekistanYieldCurvesApp:curveLimitsUpdate', function(event, result) {
            vm.curveLimits = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
