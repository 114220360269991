(function () {
    'use strict';
    angular
        .module('uzbekistanYieldCurvesApp')
        .factory('DataExportOpen', DataExportOpen);

    DataExportOpen.$inject = ['$resource'];

    function DataExportOpen($resource) {
        var resourceUrl = 'api/open/download/';

        return function (exportType) {
            if (exportType === 'excel') {
                return $resource(resourceUrl + "excel/", {}, {
                    'query': {
                        method: 'GET',
                        responseType: "arraybuffer",
                        transformResponse: function (data, headers) {
                            var response = {};
                            response.data = data;
                            response.headers = headers();
                            return response;
                        }
                    }
                });
            }
            else if (exportType === 'excel-betas') {
                return $resource(resourceUrl + "excel/betas/", {}, {
                    'query': {
                        method: 'GET',
                        responseType: "arraybuffer",
                        transformResponse: function (data, headers) {
                            var response = {};
                            response.data = data;
                            response.headers = headers();
                            return response;
                        }
                    }
                });
            }
            else if (exportType === 'excel-all') {
                return $resource(resourceUrl + "excel/all/", {}, {
                    'query': {
                        method: 'GET',
                        responseType: "arraybuffer",
                        transformResponse: function (data, headers) {
                            var response = {};
                            response.data = data;
                            response.headers = headers();
                            return response;
                        }
                    }
                });
            }
        }
    }
})();
