(function() {
    'use strict';

    angular
        .module('uzbekistanYieldCurvesApp')
        .controller('PaperErrorController', PaperErrorController);

    PaperErrorController.$inject = ['PaperError'];

    function PaperErrorController(PaperError) {

        var vm = this;

        vm.paperErrors = [];

        loadAll();

        function loadAll() {
            PaperError.query(function(result) {
                vm.paperErrors = result;
                vm.searchQuery = null;
            });
        }
    }
})();
