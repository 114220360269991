(function() {
    'use strict';

    angular
        .module('uzbekistanYieldCurvesApp')
        .controller('QuotingDeleteController',QuotingDeleteController);

    QuotingDeleteController.$inject = ['$uibModalInstance', 'entity', 'Quoting'];

    function QuotingDeleteController($uibModalInstance, entity, Quoting) {
        var vm = this;

        vm.quoting = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Quoting.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
