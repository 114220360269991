(function() {
    'use strict';

    angular
        .module('uzbekistanYieldCurvesApp')
        .constant('chartConstants', {
            'lineColorSet': [
                '#E4A216',
                '#42ADAC',
                '#A585FF',
                '#5891ED',
                '#F25E5E',
                '#999EA2'
            ]
        });
})();
