(function() {
    'use strict';

    angular
        .module('uzbekistanYieldCurvesApp')
        .controller('TranslationController', TranslationController);

    TranslationController.$inject = ['Translation'];

    function TranslationController(Translation) {

        var vm = this;

        vm.translations = [];

        loadAll();

        function loadAll() {
            Translation.query(function(result) {
                vm.translations = result;
                vm.searchQuery = null;
            });
        }
    }
})();
