(function () {
    'use strict';

    angular
        .module('uzbekistanYieldCurvesApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('ext', {
                abstract: true,
                resolve: {
                    availableDates: ['YieldCurveResultsOpen', function (YieldCurveResultsOpen) {
                        return YieldCurveResultsOpen.query({datesOnly: ""}, function (res) {
                            return res.sort();
                        }).$promise;
                    }],
                    thresholdDate: ['AppDatesOpen', function (AppDatesOpen) {
                        return AppDatesOpen('old-curve-threshold').query().$promise;
                    }]
                }
            })
            .state('oz', {
                parent: 'ext',
                url: '/ext/oz',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/ext/ext-oz.html',
                        controller: 'ExtController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('uz', {
                parent: 'ext',
                url: '/ext/uz',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/ext/ext-uz.html',
                        controller: 'ExtController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('en', {
                parent: 'ext',
                url: '/ext/en',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/ext/ext-en.html',
                        controller: 'ExtController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('ru', {
                parent: 'ext',
                url: '/ext/ru',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/ext/ext-ru.html',
                        controller: 'ExtController',
                        controllerAs: 'vm'
                    }
                }
            });
    }
})();
