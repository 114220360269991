(function () {
    'use strict';

    angular
        .module('uzbekistanYieldCurvesApp')
        .provider('chartConfig', chartConfig);

    // configuration
    chartConfig.$inject = ['chartConstants'];
    function chartConfig(chartConstants) {
        this.yieldCurveChartDefaultObject = {
            // title: {
            //     //Title text is to be filled in the app
            //     text: '',
            //     //Configure chart title font
            //     font:{
            //         opacity: 1,
            //         fontFamily: "Open Sans",
            //         fontWeight: 'lighter',
            //         color: "#337AB6",
            //         size: '16px'
            //     }
            // },
            //Configure Primary X Axis
            primaryXAxis: {
                font: {
                    size: "13px",
                    fontFamily: "Helvetica",
                    color: "#000000de"
                },
                visible: true, // to be changed for empty charts
                title: {visible: false, text: 'Tenor'},
                axisLine: {visible: true},
                majorGridLines: {visible: true, },
                majorTickLines: {visible: true},
                rangePadding: 'none',
                valueType: 'double',
                labelPlacement: 'onticks',
                labelIntersectAction: 'multipleRows',
                isIndexed: false,
                range: {min: 0, interval: 365}
            },
            //Configure Primary Y Axis
            primaryYAxis: {
                font: {
                    size: "13px",
                    fontFamily: "Helvetica",
                    color: "#000000de"
                },
                title: {
                    visible: true,
                    text: 'Yield (%, annually compounded)',
                    font: {
                        size: "13px",
                        fontFamily: "Helvetica",
                        color: "#000000de"
                    }
                },
                visible: true, // to be changed for empty charts
                axisLine: {visible: true},
                opacity: 0,
                majorGridLines: {visible: true}//, rangePadding: 'additional'
            },
            //Configure Common Properties for all the series
            commonSeriesOptions: {
                enableAnimation: true,
                opacity: 0.75,
                border: {
                    width: 2
                },
                marker: {
                    border: {
                        width: 0
                    }
                },
                highlightSettings: {
                    enable: true,
                    mode: 'series',
                    opacity: 1
                }
            },
            //Series are to be filled in the app
            series: [],
            //Configure Zooming
            zooming: {
                enable: false,
                enableMouseWheel: false,
                enableScrollbar: true,
                type: 'x,y'
            },
            //Configure Trackball
            crosshair: {
                visible: true,
                type: 'trackball'
            },
            //OnLoad event handler
            // load: onChartLoaded,
            //Chart appearance and other properties
            chartArea: {
                border: {
                    opacity: 1
                }
            },
            border: {
                width: 0,
                color: "#5250A1"
            },
            canResize: true,
            isResponsive: true,
            legend: {
                visible: true,
                shape: 'seriesType',
                font: {
                    size: "13px",
                    fontFamily: "Helvetica",
                    color: "#000000de"
                }
            },
            size: {
                height: '250'//,width: '300'
            },
            annotations: [{
                visible: false, // to be changed for empty charts
                content: "watermark",
                opacity: 0.2,
                region: "series"
            }],
            //customize the trackball tooltip
            // tooltip: {visible: true, format: " #point.text# : #point.y#  "},
            trackToolTip: onTooltipShown,
            axesLabelRendering: onAxisLabelRender
        };

        this.yieldCurveChartDefaultLineStyles = {
            predefined: [ //todo: only six lines are predefined so far, will see if more is needed
                {
                    width: 3,
                    color: chartConstants.lineColorSet[0],
                    dashSetting: [1, 0],
                    markerShape: 'Circle',
                    markerSize: 2
                },
                {
                    width: 3,
                    color: chartConstants.lineColorSet[1],
                    dashSetting: [1, 0],
                    markerShape: 'Diamond',
                    markerSize: 2
                },
                {
                    width: 3,
                    color: chartConstants.lineColorSet[2],
                    dashSetting: [1, 0],
                    markerShape: 'InvertedTriangle',
                    markerSize: 2
                },
                {
                    width: 3,
                    color: chartConstants.lineColorSet[3],
                    dashSetting: [1, 0],
                    markerShape: 'Star',
                    markerSize: 2
                },
                {
                    width: 3,
                    color: chartConstants.lineColorSet[4],
                    dashSetting: [1, 0],
                    markerShape: 'Triangle',
                    markerSize: 2
                },
                {
                    width: 3,
                    color: chartConstants.lineColorSet[5],
                    dashSetting: [1, 0],
                    markerShape: 'Pentagon',
                    markerSize: 2
                }
            ],
            new: {
                width: 3,
                color: null, //will be picked automatically
                dashSetting: [1, 0],
                markerShape: 'None',
                markerSize: 0
            }
        };

        this.xTicksTranslations = {
            "today": {en: "today", uz: "bugun", oz: "бугун", ru: "сегодня"},
            "1 day": {en: "1 day", uz: "1 kun", oz: "1 кун", ru: "1 день"},
            "1 week": {en: "1 week", uz: "1 hafta", oz: "1 ҳафта", ru: "1 неделя"},
            "2 weeks": {en: "2 weeks", uz: "2 hafta", oz: "2 ҳафта", ru: "2 недели"},
            "3 weeks": {en: "3 weeks", uz: "3 hafta", oz: "3 ҳафта", ru: "3 недели"},
            "1 month": {en: "1 month", uz: "1 oy", oz: "1 ой", ru: "1 месяц"},
            "2 months": {en: "2 months", uz: "2 oy", oz: "2 ой", ru: "2 месяца"},
            "1 quarter": {en: "1 quarter", uz: "1 chorak", oz: "1 чорак", ru: "1 квартал"},
            "2 quarters": {en: "2 quarters", uz: "2 chorak", oz: "2 чорак", ru: "2 квартала"},
            "3 quarters": {en: "3 quarters", uz: "3 chorak", oz: "3 чорак", ru: "3 квартала"},
            "1 year": {en: "1 year", uz: "1 yil", oz: "1 йил", ru: "1 год"},
            "5 quarters": {en: "5 quarters", uz: "5 chorak", oz: "5 чорак", ru: "5 кварталов"},
            "6 quarters": {en: "6 quarters", uz: "6 chorak", oz: "6 чорак", ru: "6 кварталов"},
            "7 quarters": {en: "7 quarters", uz: "7 chorak", oz: "7 чорак", ru: "7 кварталов"},
            "2 years": {en: "2 years", uz: "2 yil", oz: "2 йил", ru: "2 года"},
            "3 years": {en: "3 years", uz: "3 yil", oz: "3 йил", ru: "3 года"},
            "4 years": {en: "4 years", uz: "4 yil", oz: "4 йил", ru: "4 года"},
            "5 years": {en: "5 years", uz: "5 yil", oz: "5 йил", ru: "5 лет"},
            "6 years": {en: "6 years", uz: "6 yil", oz: "6 йил", ru: "6 лет"},
            "7 years": {en: "7 years", uz: "7 yil", oz: "7 йил", ru: "7 лет"},
            "8 years": {en: "8 years", uz: "8 yil", oz: "8 йил", ru: "8 лет"},
            "9 years": {en: "9 years", uz: "9 yil", oz: "9 йил", ru: "9 лет"},
            "10 years": {en: "10 years", uz: "10 yil", oz: "10 йил", ru: "10 лет"},
            "11 years": {en: "11 years", uz: "11 yil", oz: "11 йил", ru: "11 лет"},
            "12 years": {en: "12 years", uz: "12 yil", oz: "12 йил", ru: "12 лет"},
            "13 years": {en: "13 years", uz: "13 yil", oz: "13 йил", ru: "13 лет"},
            "14 years": {en: "14 years", uz: "14 yil", oz: "14 йил", ru: "14 лет"},
            "15 years": {en: "15 years", uz: "15 yil", oz: "15 йил", ru: "15 лет"},
            "16 years": {en: "16 years", uz: "16 yil", oz: "16 йил", ru: "16 лет"},
            "17 years": {en: "17 years", uz: "17 yil", oz: "17 йил", ru: "17 лет"},
            "18 years": {en: "18 years", uz: "18 yil", oz: "18 йил", ru: "18 лет"},
            "19 years": {en: "19 years", uz: "19 yil", oz: "19 йил", ru: "19 лет"},
            "20 years": {en: "20 years", uz: "20 yil", oz: "20 йил", ru: "20 лет"},
            "21 years": {en: "21 years", uz: "21 yil", oz: "21 йил", ru: "21 год"},
            "22 years": {en: "22 years", uz: "22 yil", oz: "22 йил", ru: "22 года"},
            "23 years": {en: "23 years", uz: "23 yil", oz: "23 йил", ru: "23 года"},
            "24 years": {en: "24 years", uz: "24 yil", oz: "24 йил", ru: "24 года"},
            "25 years": {en: "25 years", uz: "25 yil", oz: "25 йил", ru: "25 лет"},
            "26 years": {en: "26 years", uz: "26 yil", oz: "26 йил", ru: "26 лет"},
            "27 years": {en: "27 years", uz: "27 yil", oz: "27 йил", ru: "27 лет"},
            "28 years": {en: "28 years", uz: "28 yil", oz: "28 йил", ru: "28 лет"},
            "29 years": {en: "29 years", uz: "29 yil", oz: "29 йил", ru: "29 лет"},
            "30 years": {en: "30 years", uz: "30 yil", oz: "30 йил", ru: "30 лет"}
        };

        this.$get = function () {
            return {
                yieldCurveChartDefaults: {
                    chartObject: this.yieldCurveChartDefaultObject,
                    lineStyles: this.yieldCurveChartDefaultLineStyles,
                    xTicksTranslations: this.xTicksTranslations
                }
            };
        };

        function onTooltipShown(sender) {
            var dataPoint = sender.data.Series.dataPoint[sender.data.pointIndex];
            //reformat Y number up to 4th precision point
            sender.data.currentText = dataPoint.text + " : " + dataPoint.y.toPrecision(4);
        }

        function onAxisLabelRender(sender) {
            // format horizontal axis
            if (sender.data.axis.orientation === 'horizontal') {
                var actInterval = angular.copy(sender.data.axis.actualRange.interval);
                actInterval = (actInterval >= 365) ? 365 : 1;
                var freqLetter = (actInterval === 365) ? "Y" : "D";
                sender.data.label.Text = (sender.data.label.Text == "0")
                    ? "" // show "" for the zero tenor
                    : parseFloat(sender.data.label.Text) / actInterval + freqLetter;
            }
        }
    }
})();
