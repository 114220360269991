(function() {
    'use strict';

    angular
        .module('uzbekistanYieldCurvesApp')
        .controller('QuotingDetailController', QuotingDetailController);

    QuotingDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Quoting'];

    function QuotingDetailController($scope, $rootScope, $stateParams, previousState, entity, Quoting) {
        var vm = this;

        vm.quoting = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('uzbekistanYieldCurvesApp:quotingUpdate', function(event, result) {
            vm.quoting = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
