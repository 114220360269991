(function() {
    'use strict';

    angular
        .module('uzbekistanYieldCurvesApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('curve-limits', {
            parent: 'admin',
            url: '/curve-limits',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'uzbekistanYieldCurvesApp.curveLimits.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/curve-limits/curve-limits.html',
                    controller: 'CurveLimitsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('curveLimits');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('curve-limits-detail', {
            parent: 'curve-limits',
            url: '/curve-limits/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'uzbekistanYieldCurvesApp.curveLimits.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/curve-limits/curve-limits-detail.html',
                    controller: 'CurveLimitsDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('curveLimits');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'CurveLimits', function($stateParams, CurveLimits) {
                    return CurveLimits.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'curve-limits',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('curve-limits-detail.edit', {
            parent: 'curve-limits-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/curve-limits/curve-limits-dialog.html',
                    controller: 'CurveLimitsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['CurveLimits', function(CurveLimits) {
                            return CurveLimits.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('curve-limits.new', {
            parent: 'curve-limits',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/curve-limits/curve-limits-dialog.html',
                    controller: 'CurveLimitsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                beta0: null,
                                beta1: null,
                                beta2: null,
                                beta3: null,
                                beta4: null,
                                lambda: null,
                                n: null,
                                m: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('curve-limits', null, { reload: 'curve-limits' });
                }, function() {
                    $state.go('curve-limits');
                });
            }]
        })
        .state('curve-limits.edit', {
            parent: 'curve-limits',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/curve-limits/curve-limits-dialog.html',
                    controller: 'CurveLimitsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['CurveLimits', function(CurveLimits) {
                            return CurveLimits.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('curve-limits', null, { reload: 'curve-limits' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('curve-limits.delete', {
            parent: 'curve-limits',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/curve-limits/curve-limits-delete-dialog.html',
                    controller: 'CurveLimitsDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['CurveLimits', function(CurveLimits) {
                            return CurveLimits.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('curve-limits', null, { reload: 'curve-limits' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
