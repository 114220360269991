(function() {
    'use strict';

    angular
        .module('uzbekistanYieldCurvesApp')
        .controller('PaperErrorDetailController', PaperErrorDetailController);

    PaperErrorDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PaperError', 'YieldCurveResults'];

    function PaperErrorDetailController($scope, $rootScope, $stateParams, previousState, entity, PaperError, YieldCurveResults) {
        var vm = this;

        vm.paperError = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('uzbekistanYieldCurvesApp:paperErrorUpdate', function(event, result) {
            vm.paperError = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
