(function () {
    'use strict';

    angular
        .module('uzbekistanYieldCurvesApp')
        .controller('CurveLimitsDialogController', CurveLimitsDialogController);

    CurveLimitsDialogController.$inject = ['$timeout', '$scope', '$rootScope', '$stateParams', '$uibModalInstance', '$translate', 'entity', 'CurveLimits'];

    function CurveLimitsDialogController($timeout, $scope, $rootScope, $stateParams, $uibModalInstance, $translate, entity, CurveLimits) {
        var vm = this;

        vm.curveLimits = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        var tenorLabels = [
            {value: 0, label: {en: "O/N", ka: "O/N"}},
            {value: 1, label: {en: "1 day", ka: "1 დღე"}},
            {value: 7, label: {en: "1 week", ka: "1 კვირის"}},
            {value: 14, label: {en: "2 weeks", ka: "2 კვირა"}},
            {value: 21, label: {en: "3 weeks", ka: "3 კვირა"}},
            {value: 30, label: {en: "1 month", ka: "1 თვე"}},
            {value: 61, label: {en: "2 months", ka: "2 თვე"}},
            {value: 91, label: {en: "1 quarter", ka: "1 კვარტალი"}},
            {value: 183, label: {en: "2 quarters", ka: "2 კვარტალი"}},
            {value: 274, label: {en: "3 quarters", ka: "3 კვარტალი"}},
            {value: 365, label: {en: "1 year", ka: "1 წელი"}},
            {value: 457, label: {en: "5 quarters", ka: "5 კვარტალი"}},
            {value: 548, label: {en: "6 quarters", ka: "6 კვარტალი"}},
            {value: 640, label: {en: "7 quarters", ka: "7 კვარტალი"}},
            {value: 731, label: {en: "2 years", ka: "2 წელი"}},
            {value: 1096, label: {en: "3 years", ka: "3 წელი"}},
            {value: 1461, label: {en: "4 years", ka: "4 წელი"}},
            {value: 1826, label: {en: "5 years", ka: "5 წელი"}},
            {value: 2192, label: {en: "6 years", ka: "6 წელი"}},
            {value: 2557, label: {en: "7 years", ka: "7 წელი"}},
            {value: 2922, label: {en: "8 years", ka: "8 წელი"}},
            {value: 3287, label: {en: "9 years", ka: "9 წელი"}},
            {value: 3653, label: {en: "10 years", ka: "10 წელი"}},
            {value: 4018, label: {en: "11 years", ka: "11 წელი"}},
            {value: 4383, label: {en: "12 years", ka: "12 წელი"}},
            {value: 4748, label: {en: "13 years", ka: "13 წელი"}},
            {value: 5114, label: {en: "14 years", ka: "14 წელი"}},
            {value: 5479, label: {en: "15 years", ka: "15 წელი"}},
            {value: 5844, label: {en: "16 years", ka: "16 წელი"}},
            {value: 6209, label: {en: "17 years", ka: "17 წელი"}},
            {value: 6575, label: {en: "18 years", ka: "18 წელი"}},
            {value: 6940, label: {en: "19 years", ka: "19 წელი"}},
            {value: 7305, label: {en: "20 years", ka: "20 წელი"}},
            {value: 7670, label: {en: "21 years", ka: "21 წელი"}},
            {value: 8036, label: {en: "22 years", ka: "22 წელი"}},
            {value: 8401, label: {en: "23 years", ka: "23 წელი"}},
            {value: 8766, label: {en: "24 years", ka: "24 წელი"}},
            {value: 9131, label: {en: "25 years", ka: "25 წელი"}},
            {value: 9497, label: {en: "26 years", ka: "26 წელი"}},
            {value: 9862, label: {en: "27 years", ka: "27 წელი"}},
            {value: 10227, label: {en: "28 years", ka: "28 წელი"}},
            {value: 10592, label: {en: "29 years", ka: "29 წელი"}},
            {value: 10958, label: {en: "30 years", ka: "30 წელი"}}
        ];
        vm.langKey = $translate.storage().get('NG_TRANSLATE_LANG_KEY').trim().toLowerCase();
        vm.tenorLabels = tenorLabels.map(function (el) {
            return {value: el.value, label: el.label[vm.langKey]};
        });
        var translateChangeSuccess = $rootScope.$on('$translateChangeSuccess', function () {
            vm.langKey = $translate.storage().get('NG_TRANSLATE_LANG_KEY').trim().toLowerCase();
            vm.tenorLabels = tenorLabels.map(function (el) {
                return {value: el.value, label: el.label[vm.langKey]};
            });
        });
        $rootScope.$on('$destroy', function () {
            if (angular.isDefined(translateChangeSuccess) && translateChangeSuccess !== null) {
                translateChangeSuccess();
            }
        });

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.curveLimits.id !== null) {
                CurveLimits.update(vm.curveLimits, onSaveSuccess, onSaveError);
            } else {
                CurveLimits.save(vm.curveLimits, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('uzbekistanYieldCurvesApp:curveLimitsUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.validFrom = false;
        vm.datePickerOpenStatus.validTo = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

    }
})();
