(function() {
    'use strict';

    angular
        .module('uzbekistanYieldCurvesApp')
        .controller('YieldCurveResultsDialogController', YieldCurveResultsDialogController);

    YieldCurveResultsDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'YieldCurveResults', 'Quoting', 'EpmParams', 'PaperPrice', 'YieldCurvePoint', 'ParCurvePoint'];

    function YieldCurveResultsDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, YieldCurveResults, Quoting, EpmParams, PaperPrice, YieldCurvePoint, ParCurvePoint) {
        var vm = this;

        vm.yieldCurveResults = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.quotings = Quoting.query({filter: 'yieldcurveresults-is-null'});
        $q.all([vm.yieldCurveResults.$promise, vm.quotings.$promise]).then(function() {
            if (!vm.yieldCurveResults.quotingId) {
                return $q.reject();
            }
            return Quoting.get({id : vm.yieldCurveResults.quotingId}).$promise;
        }).then(function(quoting) {
            vm.quotings.push(quoting);
        });
        vm.epmparams = EpmParams.query({filter: 'yieldcurveresults-is-null'});
        $q.all([vm.yieldCurveResults.$promise, vm.epmparams.$promise]).then(function() {
            if (!vm.yieldCurveResults.epmParamsId) {
                return $q.reject();
            }
            return EpmParams.get({id : vm.yieldCurveResults.epmParamsId}).$promise;
        }).then(function(epmParams) {
            vm.epmparams.push(epmParams);
        });
        vm.paperprices = PaperPrice.query();
        vm.yieldcurvepoints = YieldCurvePoint.query();
        vm.parcurvepoints = ParCurvePoint.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.yieldCurveResults.id !== null) {
                YieldCurveResults.update(vm.yieldCurveResults, onSaveSuccess, onSaveError);
            } else {
                YieldCurveResults.save(vm.yieldCurveResults, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('uzbekistanYieldCurvesApp:yieldCurveResultsUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.date = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
