(function() {
    'use strict';

    angular
        .module('uzbekistanYieldCurvesApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('observed-data', {
            parent: 'entity',
            url: '/observed-data',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'uzbekistanYieldCurvesApp.observedData.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/observed-data/observed-data.html',
                    controller: 'ObservedDataController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('observedData');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('observed-data-detail', {
            parent: 'observed-data',
            url: '/observed-data/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'uzbekistanYieldCurvesApp.observedData.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/observed-data/observed-data-detail.html',
                    controller: 'ObservedDataDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('observedData');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'ObservedData', function($stateParams, ObservedData) {
                    return ObservedData.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'observed-data',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('observed-data-detail.edit', {
            parent: 'observed-data-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/observed-data/observed-data-dialog.html',
                    controller: 'ObservedDataDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ObservedData', function(ObservedData) {
                            return ObservedData.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('observed-data.new', {
            parent: 'observed-data',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/observed-data/observed-data-dialog.html',
                    controller: 'ObservedDataDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                batchId: null,
                                source: null,
                                isin: null,
                                desc: null,
                                couponRate: null,
                                freq: null,
                                settlementDate: null,
                                tradeDate: null,
                                inceptionDate: null,
                                maturityDate: null,
                                dayCountConvention: null,
                                instrumentType: null,
                                marketType: null,
                                ticketSize: null,
                                dirtyPriceObs: null,
                                cleanPriceObs: null,
                                ytmObs: null,
                                ytmMin: null,
                                ytmMax: null,
                                dirtyPriceThe: null,
                                cleanPriceThe: null,
                                accruedInterestThe: null,
                                ytmThe: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('observed-data', null, { reload: 'observed-data' });
                }, function() {
                    $state.go('observed-data');
                });
            }]
        })
        .state('observed-data.edit', {
            parent: 'observed-data',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/observed-data/observed-data-dialog.html',
                    controller: 'ObservedDataDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ObservedData', function(ObservedData) {
                            return ObservedData.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('observed-data', null, { reload: 'observed-data' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('observed-data.delete', {
            parent: 'observed-data',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/observed-data/observed-data-delete-dialog.html',
                    controller: 'ObservedDataDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['ObservedData', function(ObservedData) {
                            return ObservedData.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('observed-data', null, { reload: 'observed-data' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
